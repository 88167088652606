import * as React from "react";
import RouteLinks from "../components/router";
import Home from "../pages/home";

// markup
const IndexPage = () => {
  return (
    <Home />
  );
};

export default IndexPage;
